import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Button,
  Card,
  message,
  InputNumber,
  Select,
  Input
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { FileInputSingle, MultipleLangPicker } from '../../components';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

const ModuleCategoryDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let [langCode] = useGlobal('langCode');
  let [langCms] = useGlobal('langCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }
  let newRecord = {
    langs: [{ lang: langCode ? langCode : langCms[0].code, title: '' }],
    order: 0,
    icon:null,
    type:'multiModule'
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [validationCheck, setValidationCheck] = useState(false);

   
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);
console.log("ID  ",id)
  let get = async () => {
    if (id) {
      await api
        .get('/rest/moduleCategories/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let validate = useCallback(() => {
    let errors = {};

    if (data.order === null) errors.order = languagesCms.ENFORCED;
    if(data.icon ==null) errors.icon=languagesCms.ENFORCED;
    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/moduleCategories/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/moduleCategories');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/moduleCategories', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/moduleCategories' );
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/moduleCategories'}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.ORDER}
                  required
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <InputNumber
                    name="order"
                    value={data.order}
                    onChange={(v) => setData({ ...data, order: v })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <MultipleLangPicker
                  langCms={langCms}
                  langCode={langCode}
                  languagesCms={languagesCms}
                  title={'title'}
                  record={data}
                  setRecord={setData}
                  name="lang"
                />
              </Col>
            </Row>
            <Row>
            <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="icon" help={errors.icon}
                                           validateStatus={errors.icon ? 'error' : 'success'}>
                                    <FileInputSingle languagesCms={languagesCms} record={data} setRecord={setData} name="icon"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Tip"
                  required
                  help={errors.type}
                  validateStatus={errors.type ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.type}
                    style={{ width: 120 }}
                    onChange={(e) => setData({ ...data, type: e })}
                  >
                    <Select.Option value="multiModule">Popup Şeklinde Açılsın</Select.Option>
                    <Select.Option value="tabModule">Tab Şeklinde Açılsın</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ModuleCategoryDetail;
