import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Card,
  message,
  InputNumber,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { GroupPicker } from '../../components';
import { LeftOutlined } from '@ant-design/icons';
import { ModuleCategoriesPicker} from '../../components';
import qs from 'qs';
import api from '../../service/index';
import { Link } from 'react-router-dom';

const ModuleDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let [, setModules] = useGlobal('modules');
  let id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');

  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api
        .get('/rest/modules/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let query = {
    lang: langCode ? langCode : langCms[0].code,
    adminShow: 'true',
  };

  let save = async () => {
    if (id) {
      await api
        .put('/rest/modules/' + id, data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);

            api.get(`/rest/modules?${qs.stringify(query)}`).then(({ data }) => {
              setModules(data.result.rows);
              localStorage.setItem('modules', JSON.stringify(data.result.rows));
              //props.history.push(data.result.rows[0]._id);
            });
            history.push('/modules');
          } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        });
    } else {
      api
        .post('/rest/modules', data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/modules');
          } else {
            message.error(result_message.message, 2);
          }
        });
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{languagesCms.MODULES}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/modules">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading && (
          <Card title={id ? languagesCms.EDIT_MODUL : languagesCms.ADD_MODUL}>
            <Form layout="horizontal" size={'large'} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="id"
                    help={errors._id}
                    validateStatus={errors._id ? 'error' : 'success'}
                  >
                    <Input
                      name="_id"
                      disabled={true}
                      value={data._id}
                      onChange={(e) =>
                        setData({ ...data, _id: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="refId"
                    help={errors.refId}
                    validateStatus={errors.refId ? 'error' : 'success'}
                  >
                    <Input
                      name="refId"
                      disabled={true}
                      value={data.refId}
                      onChange={(e) =>
                        setData({ ...data, refId: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                        <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={"Modül Kategorisi"} help={errors.groups}
                                           validateStatus={errors.active ? 'error' : 'success'}>
                                    <ModuleCategoriesPicker languagesCms={languagesCms} lang={data.lang ? data.lang : ( langCode ? langCode : langCms[0].code)} record={data} setRecord={setData} name="categoryId"/>
                                </Form.Item>
                            </Col>
                        </Row>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.NAME_MODUL}
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Input
                      name="name"
                      value={data.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.GROUP}
                    help={errors.groups}
                    validateStatus={errors.active ? 'error' : 'success'}
                  >
                    <GroupPicker
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="groups"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    label={languagesCms.SEE_ADMIN}
                    help={errors.adminShow}
                    validateStatus={errors.adminShow ? 'error' : 'success'}
                  >
                    <Switch
                      checked={data.adminShow ? true : false}
                      checkedChildren={languagesCms.SHOW}
                      unCheckedChildren={languagesCms.DONTSHOW}
                      onChange={(v) => setData({ ...data, adminShow: v })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    label={languagesCms.SEE_APP}
                    help={errors.appShow}
                    validateStatus={errors.appShow ? 'error' : 'success'}
                  >
                    <Switch
                      checked={data.appShow ? true : false}
                      checkedChildren={languagesCms.SHOW}
                      unCheckedChildren={languagesCms.DONTSHOW}
                      onChange={(v) => setData({ ...data, appShow: v })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    label={languagesCms.SEE_WEB}
                    help={errors.webShow}
                    validateStatus={errors.webShow ? 'error' : 'success'}
                  >
                    <Switch
                      checked={data.webShow ? true : false}
                      checkedChildren={languagesCms.SHOW}
                      unCheckedChildren={languagesCms.DONTSHOW}
                      onChange={(v) => setData({ ...data, webShow: v })}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="Modül Kapalı Mesajı">
                    <Input
                      name="statusMessage"
                      value={data.statusMessage}
                      onChange={(e) =>
                        setData({ ...data, statusMessage: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.VERSION}
                    help={errors.version}
                    validateStatus={errors.version ? 'error' : 'success'}
                  >
                    <Input
                      name="version"
                      value={data.version}
                      onChange={(e) =>
                        setData({ ...data, version: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.LANG}
                    help={errors.lang}
                    validateStatus={errors.version ? 'error' : 'success'}
                  >
                    <Input
                      name="lang"
                      value={data.lang}
                      onChange={(e) =>
                        setData({ ...data, lang: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.BACKGROUND_COLOR}
                    help={errors.backgroundColor}
                    validateStatus={
                      errors.backgroundColor ? 'error' : 'success'
                    }
                  >
                    <Input
                      name="backgroundColor"
                      value={data.backgroundColor}
                      onChange={(e) =>
                        setData({ ...data, backgroundColor: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.TEXT_COLOR}
                    help={errors.textColor}
                    validateStatus={errors.textColor ? 'error' : 'success'}
                  >
                    <Input
                      name="name"
                      value={data.textColor}
                      onChange={(e) =>
                        setData({ ...data, textColor: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.ORDER}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <InputNumber
                      name="order"
                      value={data.order}
                      onChange={(v) => setData({ ...data, order: v })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Type"
                    help={errors.type}
                    validateStatus={errors.type ? 'error' : 'success'}
                  >
                    <Input
                      name="type"
                      value={data.type}
                      onChange={(e) =>
                        setData({ ...data, type: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="İkon Url"
                    help={errors.icon}
                    validateStatus={errors.icon ? 'error' : 'success'}
                  >
                    <Input
                      name="icon"
                      value={data.icon}
                      onChange={(e) =>
                        setData({ ...data, icon: e.target.value })
                      }
                    />
                    <p>
                      Resim isimlerini refId alanındaki isim ile aynı
                      kullanılması gerekiyor.
                    </p>
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="Bottom Bar Icon"
                    help={errors.bottomBarIcon}
                    validateStatus={errors.bottomBarIcon ? 'error' : 'success'}
                  >
                    <Input
                      name="bottomBarIcon"
                      value={data.bottomBarIcon}
                      onChange={(e) =>
                        setData({ ...data, bottomBarIcon: e.target.value })
                      }
                    />
                    <p>
                      Resim isimlerini refId alanındaki isim ile aynı
                      kullanılması gerekiyor.
                    </p>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                      {' '}
                      {languagesCms.SAVE}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </div>
    </div>
  );
};

export default ModuleDetail;
