import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let {
    record,
    setRecord,
    name,
    coordinateTypeChange = '1',
    setCoordinateTypeChange,
  } = props;
  let [coordinateTypes, setCoordinateTypes] = useState([]);
  let _params = {
    sort: 'order',
    page: 1,
    pageSize: 100,
    lang: 'TR',
    appShow: 'true',
  };
  let languagesCms = props.languagesCms;
  useMemo(() => {
    api
      .get(`/rest/coordinateTypes?${qs.stringify(_params)}`)
      .then(({ data }) => {
        if (data && data.result && data.result.rows.length > 0) {
          let dbCoordinateTypes = data.result.rows.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          // sort by alphabetically
          dbCoordinateTypes = dbCoordinateTypes.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setCoordinateTypes(dbCoordinateTypes);
        }
      });
  }, []);

  return (
    <Select
      value={record[name] || []}
      placeholder={languagesCms.SELECT_TYPE}
      onChange={(v) => {
        if (coordinateTypeChange !== '1') setCoordinateTypeChange(true);
        setRecord({ ...record, [name]: v });
      }}
    >
      {coordinateTypes &&
        coordinateTypes.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
